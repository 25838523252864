import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { createPinia } from 'pinia';
import './index.css';

// Import necessary Vuetify components
import { VListItem, VListItemTitle, VListItemSubtitle, VListItemAction } from 'vuetify/components';

const app = createApp(App);

// Use Vuetify, router, and Pinia
app.use(router);
app.use(vuetify);
app.use(createPinia());

// Globally register necessary Vuetify components
app.component('VListItem', VListItem);
app.component('VListItemTitle', VListItemTitle);
app.component('VListItemSubtitle', VListItemSubtitle);
app.component('VListItemAction', VListItemAction);

// Mount the app
app.mount('#app');
