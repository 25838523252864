import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'LandingView',
    component: () => import('@/views/LandingView.vue')
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/take-my-quest',
    name: 'TakeMyQuestView',
    component: () => import('@/views/TakeMyQuestView.vue')
  },
  {
    path: '/homelab',
    name: 'HomelabView',
    component: () => import('@/views/HomelabView.vue')
  },
  {
    path: '/projects',
    name: 'ProjectsView',
    component: () => import('@/views/ProjectsView.vue')
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: () => import('@/views/ContactView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundView',
    component: () => import('@/views/NotFoundView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
