import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomTheme', // Nom de ton thème personnalisé
    themes: {
      myCustomTheme: {
        colors: {
          primary: '#24343d', // Ta couleur principale
          secondary: '#d9e5ec', // Optionnel : couleur secondaire
          // Ajoute d'autres couleurs si nécessaire
        },
      },
    },
  },
});
